/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';
import {
  queryMenus,
  queryCategoryInfo,
  queryCategoryAll,
  queryArticleAll,
  queryArticleInfo,
  queryArticleInfoByName,
  queryAd,
  queryCategoryInfoByName,
  queryChildrenCategoryAll,
  queryTreeCategoryById,
  queryDataSiteUrl,
  queryProductAll,
  queryProductInfo,
  queryProductsCataLog,
  getListProducers,
  queryOrderByToken,
  createOrders,
  queryPricingInfor,
  queryServicesInfor,
  queryOpenShedules,
  queryProductVACAll,
  queryProductVACInfo,
  createProductVAC,
  queryProductCollection,
  queryComboTours,
  queryHotel,
  queryTravelVisaPackageGroups,
  queryTravelVisa,
  queryProvincesFlights,
  queryFlights,
  querycountries,
  queryTravelHotelGroups,
  queryTravelService,
  queryHotelDetail,
  queryFlightsTicket,
  queryFlightsLuggage,
  queryProvinces,
  queryProvincesSelect,
  queryComboToursDetail,
  queryCatalogArticle,
  queryArticlesDetail,
  flightBookNormal,
  queryCountriesAll,
  queryAutoComplete,
  queryAutoCompleteSearch,
  queryListBank,
  paymentMerchant,
  createToken,
  verifyToken,
  queryOrdersInfo,
  queryProvinceDetail,
  updateOrders,
  queryBankAccount,
  queryUnitBranch,
  checkFee,
  queryPaymentMethods,
  queryVouchersMoney,
  queryQuiz,
  queryQuizzCustomer,
  queryAirports,
  querySearchAirports,
  queryAirLaneData
} from '@/services/websRedux';

export default {
  namespace: 'webs',

  state: {
    dataAll: [],
    dataAdd: [],
    data: {
      list: [],
      pagination: {},
    },
    info: {},
    query: {},
    filter: {},
    dataCategoryInfo: {},
    dataCategoryAll: [],
    dataSiteUrl: {},
    dataArticleAll: [],
    datainfoArticle: {},
    dataTour: {},
    dataHotel: {},
    dataChuyenBay: {},
    dataAd: [],
    dataSite: {},
    products: {
      list: [],
      pagination: {},
    },
    dataProductInfo: {},
    dataProducers: [],
    dataCheckout: {},
    dataOpenShedules: {},
    saveHeader: {},
    visaCall: {
    },
    airLineDataResult: [],
    airPortDataResult: [],
  },
  effects: {
    *fetchQuiz({ payload, callback }, { call, put }) {
      const response = yield call(queryQuiz, payload);
      // console.log("servie model fetch response: %o ", response)
     
      if (callback) callback(response);
    },
    *headerText({ payload }, { put }) {
      if (payload) {
        yield put({ type: 'saveHeader', payload: payload || {} });
      }
    },
    *visaCalls({ payload }, { put }) {
      if (payload) {
        yield put({ type: 'visaCall', payload: payload || {} });
      }
    },
    *fetchMenus({ payload, callback }, { call, put }) {
      const response = yield call(queryMenus, payload);
      // console.log("servie model fetch response: %o ", response)
      if (response) {
        yield put({
          type: 'save',
          payload: response || {},
        });
      }
      if (callback) callback(response);
    },
    *fetchQuery({ payload }, { put }) {
      if (payload) {
        yield put({ type: 'savequery', payload: payload || {} });
      }
    },
    // *fetchSiteInfo({ payload: { id }, callback }, { call, put }) {
    //   const response = yield call(queryWebInfo, id);
    //   // const page = yield select(state => state.users.page);
    //   // console.log("dsasad", response)
    //   if (response) {
    //     yield put({ type: 'saveSiteInfo', payload: response || {} });

    //   }
    //   if (callback)
    //     callback(response)
    // },
    *fetchCategoryInfo(
      {
        payload: { id },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryCategoryInfo, id);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveCategoryInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *createQuizzCustomer({ payload, callback }, { call }) {
      const response = yield call(queryQuizzCustomer, payload);
      if (callback) callback(response);

    },
    *fetchAllCategory({ payload, callback }, { call, put }) {
      const response = yield call(queryCategoryAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveCategoryAll', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAllCategoryLazyloading({ payload, callback }, { call }) {
      const response = yield call(queryCategoryAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)

      if (callback) callback(response);
    },
    *fetchAllArticle({ payload, callback }, { call }) {
      const response = yield call(queryArticleAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchCatalogArticle({ payload, callback }, { call }) {
      const response = yield call(queryCatalogArticle, payload);
      // console.log(response);
      if (callback) callback(response);
    },
    *fetchProvincesDetail({ payload, callback }, { call }) {
      const response = yield call(queryProvinceDetail, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAutoComplete({ payload, callback }, { call }) {
      const response = yield call(queryAutoComplete, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAutoCompleteSearch({ payload, callback }, { call }) {
      const response = yield call(queryAutoCompleteSearch, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchComboTour({ payload, callback }, { call }) {
      const response = yield call(queryComboTours, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchComboTourDetail({ payload, callback }, { call, put }) {
      console.log("111");
      const response = yield call(queryComboToursDetail, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveTour', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchHotel({ payload, callback }, { call }) {
      const response = yield call(queryHotel, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchHotelDetail({ payload, callback }, { call, put }) {
      const response = yield call(queryHotelDetail, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveHotel', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchArticlesDetail({ payload, callback }, { call, put }) {
      const response = yield call(queryArticlesDetail, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveChuyenbay', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchCountriesAll({ payload, callback }, { call }) {
      const response = yield call(queryCountriesAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchTravelService({ payload, callback }, { call }) {
      const response = yield call(queryTravelService, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchTravelHotelGroups({ payload, callback }, { call }) {
      const response = yield call(queryTravelHotelGroups, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchLazyLoading({ payload, callback }, { call }) {
      const response = yield call(querycountries, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchTravelVisa({ payload, callback }, { call }) {
      const response = yield call(queryTravelVisa, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchTravelVisaPackageGroups({ payload, callback }, { call }) {
      const response = yield call(queryTravelVisaPackageGroups, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchProvincesFlights({ payload, callback }, { call }) {
      const response = yield call(queryProvincesFlights, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAirports({ payload, callback }, { call }) {
      const response = yield call(queryAirports, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchSearchAirports({ payload, callback }, { call }) {
      const response = yield call(querySearchAirports, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchFlights({ payload, callback }, { call }) {
      const response = yield call(queryFlights, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *flightBookNormal({ payload, callback }, { call }) {
      const response = yield call(flightBookNormal, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *fetchProvinces({ payload, callback }, { call }) {
      const response = yield call(queryProvinces, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *fetchProvincesSelect({ payload, callback }, { call }) {
      const response = yield call(queryProvincesSelect, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *fetchFlightsTicket({ payload, callback }, { call }) {
      const response = yield call(queryFlightsTicket, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *fetchFlightsLuggage({ payload, callback }, { call }) {
      const response = yield call(queryFlightsLuggage, payload);
      // const page = yield select(state => state.users.page);
      if (response) {
        // yield put({ type: 'saveArticle', payload: response || {} });
        if (callback) callback(response);
      }

    },
    *fetchAllAd({ payload, callback }, { call, put }) {
      const response = yield call(queryAd, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveAd', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchArticleInfoByName({ payload, callback }, { call, put }) {
      const response = yield call(queryArticleInfoByName, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveArticleInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchCategoryInfoByName(
      {
        payload: { name },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryCategoryInfoByName, name);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveCategoryInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAllChildrenCategory({ payload, callback }, { call }) {
      const response = yield call(queryChildrenCategoryAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log('dsasad', response);
      if (callback) callback(response);
    },
    *fetchTreeCategory({ payload, callback }, { call }) {
      const response = yield call(queryTreeCategoryById, payload);
      if (callback) callback(response);
    },
    *fetchArticleInfo({ payload, callback }, { call }) {
      const response = yield call(queryArticleInfo, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        // yield put({ type: 'saveArticleInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchSiteUrl({ payload, callback }, { call, put }) {
      const response = yield call(queryDataSiteUrl, payload);
      if (response) {
        yield put({ type: 'saveDataSiteUrl', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchAllProduct({ payload, callback }, { call }) {
      const response = yield call(queryProductAll, payload);
      if (response) {
        // yield put({ type: 'saveProduct', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchProductInfo({ payload, callback }, { call, put }) {
      const response = yield call(queryProductInfo, payload);
      if (response) {
        yield put({ type: 'saveProductInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchProducers({ payload, callback }, { call }) {
      // console.log("asdaf")
      const response = yield call(getListProducers, payload);
      if (response) {
        // yield put({ type: 'saveProducers', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchOrderByToken({ payload, callback }, { call, put }) {
      const response = yield call(queryOrderByToken, payload);
      if (response) {
        yield put({ type: 'saveCheckout', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *postCreateOrder({ payload, callback }, { call }) {
      const response = yield call(createOrders, payload);
      if (callback) callback(response);
    },
    *fetchInfoOrder({ payload: { id }, callback }, { call }) {
      const response = yield call(queryOrdersInfo, id)
      if (callback) callback(response)
    },
    *updateOrders({ payload, callback }, { call }) {
      const response = yield call(updateOrders, payload)
      if (callback) callback(response)
    },
    *fetchAllPricingInfor({ payload, callback }, { call }) {
      const response = yield call(queryPricingInfor, payload);
      // const page = yield select(state => state.users.page);

      if (response) {
        // yield put({ type: 'savePricingInfor', payload: response || {} });
        if (callback) callback(response);
      }
    },
    *fetchServicesInfor({ payload, callback }, { call }) {
      const response = yield call(queryServicesInfor, payload);
      // const page = yield select(state => state.users.page);

      if (response) {
        // yield put({ type: 'saveServicesInfor', payload: response || {} });
        if (callback) callback(response);
      }
    },
    *fetchOpenShedules({ payload, callback }, { call }) {
      const response = yield call(queryOpenShedules, payload);
      // const page = yield select(state => state.users.page);

      if (response) {
        // yield put({ type: 'saveOpenShedules', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *postProductVAC({ payload, callback }, { call }) {
      const response = yield call(createProductVAC, payload);
      if (response) {
        if (callback) callback(response);
      }
    },
    *fetchProductVAC({ payload, callback }, { call }) {
      const response = yield call(queryProductVACAll, payload);
      if (response) {
        if (callback) callback(response);
      }
    },
    *fetchProductVACInfo({ payload, callback }, { call }) {
      const response = yield call(queryProductVACInfo, payload);
      if (response) {
        if (callback) callback(response);
      }
    },
    *fetchCategoryInfoById(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(queryCategoryInfo, id);
      if (callback) callback(response);
    },
    *fetchAllProductCatalog({ payload, callback }, { call }) {
      const response = yield call(queryProductsCataLog, payload);
      if (response) {
        // yield put({ type: 'saveProduct', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchDataProductCollection({ payload, callback }, { call }) {
      const response = yield call(queryProductCollection, payload);
      if (callback) callback(response);
    },
    *fetchListBank({ payload, callback }, { call }) {
      const response = yield call(queryListBank, payload);
      if (callback) callback(response);
    },
    *paymentMerchant({ payload, callback }, { call }) {
      const response = yield call(paymentMerchant, payload);
      if (callback) callback(response);
    },
    *createToken({ payload, callback }, { call }) {
      const response = yield call(createToken, payload);
      if (callback) callback(response);
    },
    *verifyToken({ payload, callback }, { call }) {
      const response = yield call(verifyToken, payload);
      if (callback) callback(response);
    },
    *fetchBankAccount({ payload, callback }, { call }) {
      const response = yield call(queryBankAccount, payload);
      if (callback) callback(response);
    },
    *fetchUnitBranch({ payload, callback }, { call }) {
      const response = yield call(queryUnitBranch, payload);
      if (callback) callback(response);
    },
    *checkFee({ payload, callback }, { call }) {
      const response = yield call(checkFee, payload);
      if (callback) callback(response);
    },
    *fetchPaymentMethods({ payload, callback }, { call }) {
      const response = yield call(queryPaymentMethods, payload);
      if (callback) callback(response);
    },
    *fetchVouchersMoney({ payload, callback }, { call }) {
      const response = yield call(queryVouchersMoney, payload);
      if (callback) callback(response);
    },
    *fetchAirLaneData({ payload, callback }, { call }) {
      const response = yield call(queryAirLaneData, payload);
      if (callback) callback(response);
      // yield put({ type: 'saveAirLine', payload: response || [] });
    },
    *saveAirLineData({ payload,  }, { put }) {
        yield put({ type: 'saveAirLine', payload: payload || [] });
    },
    *saveAirPortData({ payload,  }, { put }) {
        yield put({ type: 'saveAirport', payload: payload || [] });
    },
  },

  reducers: {
    saveAd(state, action) {
      return { ...state, dataAd: action.payload.result };
    },
    saveAirLine(state, action) {
      return { ...state, airLineDataResult: action.payload };
    },
    saveAirport(state, action) {
      return { ...state, airPortDataResult: action.payload };
    },
    save(state, action) {
      return { ...state, data: action.payload.result };
    },
    saveHotel(state, action) {
      return {
        ...state,
        dataHotel: action.payload
      }
    },
    saveTour(state, action) {
      return {
        ...state,
        dataTour: action.payload
      }
    },
    saveChuyenbay(state, action) {
      return {
        ...state,
        dataChuyenBay: action.payload
      }
    },
    savequery(state, action) {
      return { ...state, query: action.payload };
    },
    saveHeader(state, action) {
      return { ...state, saveHeader: action.payload };
    },
    visaCall(state, action) {
      return { ...state, visaCall: action.payload };
    },
    saveArticleInfo(state, action) {
      return { ...state, datainfoArticle: action.payload };
    },
    saveCategoryInfo(state, action) {
      return { ...state, dataCategoryInfo: action.payload };
    },
    saveCategoryAll(state, action) {
      return { ...state, dataCategoryAll: action.payload };
    },
    saveArticle(state, action) {
      return { ...state, dataArticleAll: action.payload.result };
    },
    saveChildrenCategoryAll(state, action) {
      return {
        ...state,
        dataChildrenCategoryAll:
          (action.payload && action.payload.result && action.payload.result.list) || [],
      };
    },
    saveDataSiteUrl(state, action) {
      return {
        ...state,
        dataSiteUrl: action.payload,
        dataSite:
          action.payload &&
          action.payload.result &&
          action.payload.result.list.length > 0 &&
          action.payload.result.list[0],
      };
    },
    saveProducts(state, action) {
      return { ...state, products: action.payload.result };
    },
    saveProductInfo(state, action) {
      return { ...state, dataProductInfo: action.payload };
    },
    saveProducers(state, action) {
      return { ...state, dataProducers: action.payload };
    },
    saveCheckout(state, action) {
      return { ...state, dataCheckout: action.payload };
    },
    saveOpenShedules(state, action) {
      return {
        ...state,
        dataOpenShedules: action.payload,
      };
    },
    saveProductCatalog(state, action) {
      return {
        ...state,
        dataProductCatalog: action.payload,
      };
    },
  },
};
