import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';
// import log from '@/utils/log';

// user
// eslint-disable-next-line import/prefer-default-export
export async function queryMenus(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/menus/find/all/parent-child?${stringify(query)}`);
}

export async function queryCategoryInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/categories/${id}`);
}
export async function queryCategoryInfoByName(name) {
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${name}`);

  return request(`${CONFIG.API_SERVER_WEB}/categories/${name}`);
}
export async function queryCategoryAll(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/categories?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/categories?${stringify(query)}`);
}

export async function queryArticleAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/articles?${stringify(query)}`);
}

export async function queryComboTours(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelTours/getlist/tours?${stringify(query)}`);
}
export async function queryQuiz(params) {
  const { filter, sort, range } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['countriesId', 'DESC']),
    range: JSON.stringify(range || [0, 19]),
  };

  return request(`${CONFIG.API_SERVER_WEB}/quizz?${stringify(query)}`);
}

export async function queryQuizzCustomer(params) {
  return request(`${CONFIG.API_SERVER_WEB}/quizzCustomers`, {
    method: "POST",
    body: {
      ...params,
      method: 'post'
    }
  });
}
export async function queryComboToursDetail(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    // sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }


  return request(`${CONFIG.API_SERVER_WEB}/travelTours/get/byid?${stringify(query)}`);
}

export async function queryHotel(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelHotels/getlist/hotels?${stringify(query)}`);
}
export async function queryHotelDetail(params) {
  return request(`${CONFIG.API_SERVER_WEB}/travelHotels/byid/${params.id}`);
}
export async function queryArticlesDetail(params) {
  // console.log(`${CONFIG.API_SERVER_WEB}/articles/${params.id}`);
  return request(`${CONFIG.API_SERVER_WEB}/articles/${params.id}`);
}
export async function queryProvinceDetail(params) {
  return request(`${CONFIG.API_SERVER_WEB}/provinces/${params.id}`);
}
// export async function queryComboToursDetail(params) {
//   const { filter, sort, range, attributes } = params;
//   const query = {
//     filter: JSON.stringify(filter),
//     range: JSON.stringify(range || [0, 49]),
//   };
//   if (attributes) {
//     query.attributes = attributes;
//   }
//   console.log("params: ",params, `${CONFIG.API_SERVER_WEB}/travelTours/get/byid?${stringify(query)}`);
//   return request(`${CONFIG.API_SERVER_WEB}/travelTours/get/byid?${stringify(query)}`);
// }
export async function queryTravelService(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    // range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelServices?${stringify(query)}`);
}
export async function queryTravelVisa(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelVisa/getlist/visas?${stringify(query)}`);
}
export async function querycountries(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/countries/getlist/countriesFilterVisa?${stringify(query)}`);
}
export async function queryAutoComplete(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    // sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelTours/search/autocomplete?${stringify(query)}`);
}
export async function queryAutoCompleteSearch(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    // sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelTours/search/autocomplete/NameOrProvinceOrCountries?${stringify(query)}`);
}
export async function queryCountriesAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    // range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/countries?${stringify(query)}`);
}
export async function queryTravelVisaPackageGroups(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelVisaPackageGroups?${stringify(query)}`);
}
export async function queryTravelHotelGroups(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/travelHotelGroups?${stringify(query)}`);
}
export async function queryProvinces(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['provinceName', 'ASC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/provinces/getlist/provinces?${stringify(query)}`);
}
export async function queryCatalogArticle(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 20]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/articles/getlist/articles?${stringify(query)}`);
}
export async function queryAd(params) {
  const { filter, attributes, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['orderBy', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ads?${stringify(query)}`);
}

export async function queryProvincesFlights(params) {
  return request(`${CONFIG.API_SERVER_WEB}/provinces/getlist/provinces/flights?${stringify(params)}`);
}
export async function queryProvincesSelect(params) {
  return request(`${CONFIG.API_SERVER_WEB}/provinces?${stringify(params)}`);
}

export async function queryFlights(params) {
  return request(`${CONFIG.API_SERVER_WEB}/flight`, {
    method: "POST",
    body: {
      ...params,
      method: 'post'
    }
  });
}

export async function queryFlightsTicket(params) {
  return request(`${CONFIG.API_SERVER_WEB}/Flight/ticket`, {
    method: "POST",
    body: {
      ...params,
      method: 'post'
    }
  });
}
export async function queryFlightsLuggage(params) {
  return request(`${CONFIG.API_SERVER_WEB}/Flight/luggage`, {
    method: "POST",
    body: {
      ...params,
      method: 'post'
    }
  });
}

export async function flightBookNormal(params) {
  return request(`${CONFIG.API_SERVER_WEB}/flight/bookNormal`, {
    method: "POST",
    body: {
      ...params,
      method: 'post'
    }
  });
}

export async function queryArticleInfoByName(payload) {
  const { name, attributes } = payload;
  return request(`${CONFIG.API_SERVER_WEB}/articles/${name}${attributes || ''}`);
}

export async function queryWebInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/sites/${id}`);
}
export async function queryChildrenCategoryAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }

  return request(`${CONFIG.API_SERVER_WEB}/categories/find/list/parent-child?${stringify(query)}`);
}
export async function queryTreeCategoryById(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(
    `${CONFIG.API_SERVER_WEB}/categories/find/getbycategories/parent-child?${stringify(query)}`
  );
}
export async function queryArticleInfo(payload) {
  const { id, attributes } = payload;
  /* const { filter, sort } = params;
    const query = {
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort || )
    }; */
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${id}`)
  return request(`${CONFIG.API_SERVER_WEB}/articles/${id}${attributes || ''}`);
}
export async function queryDataSiteUrl(params) {
  const { filter, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/sites?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/sites?${stringify(query)}`);
}

export async function queryProductAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProducts?${stringify(query)}`);
}

export async function queryProductInfo(payload) {
  const { id, attributes } = payload;
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProducts/${id}${attributes || ''}`);
}

export async function queryProductVACAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProductsVAC?${stringify(query)}`);
}

export async function queryProductVACInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProductsVAC/${id}`);
}

export async function createProductVAC(params) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProductsVAC`, {
    body: { ...params },
    method: 'POST',
  });
}

export async function updateProductVAC({ id, ...params }) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProductsVAC/${id}`, {
    body: { ...params },
    method: 'PUT',
  });
}

export async function queryOrdersAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceOrders?${stringify(query)}`);
}

export async function queryOrdersInfo(id) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceOrders/${id}`);
}

export async function queryOrderByToken(query) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceOrders/getOrder?${stringify(query)}`);
}

export async function createOrders(params) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceOrders`, {
    body: { ...params },
    method: 'POST',
  });
}

export async function updateOrders({ id, params }) {
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceOrders/${id}`, {
    body: { ...params },
    method: 'PUT',
  });
}

export async function getListProducers(params) {
  // console.log("asfaF")
  const { filter, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(
    `${CONFIG.API_SERVER_WEB}/ecommerceProducts/getProducers/list?${stringify(query)}`
  );
}

export async function queryPricingInfor(params) {
  const { filter, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    //   sort: JSON.stringify(sort || ['name', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/servicePackages/get/all?${stringify(query)}`);
}

export async function queryServicesInfor(params) {
  const { filter, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/services?${stringify(query)}`);
}
export async function queryOpenShedules(payload) {
  return request(`${CONFIG.API_SERVER_WEB}/openShedules/places/${payload.id}`);
}

export async function queryProductsCataLog(params) {
  const { filter, sort, range, limitPerCatalog, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
    limitPerCatalog: Number(limitPerCatalog),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  if (!limitPerCatalog) {
    delete query.limitPerCatalog;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/productsCatalog/get/all?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/productsCatalog/get/all?${stringify(query)}`);
}

export async function queryProductCollection(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['createDate', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(`${CONFIG.API_SERVER_WEB}/ecommerceProductCollection?${stringify(query)}`);
}


export async function queryListBank(params) {
  return request(`${CONFIG.API_SERVER_WEB}/baokim/getlist/bank?${stringify(params)}`);
}

export async function paymentMerchant(params) {
  return request(`${CONFIG.API_SERVER_WEB}/baokim/payment`, {
    method: "POST",
    body: {
      ...params,
      method: 'post',
    }
  });
}

export async function createToken(params) {
  return request(`${CONFIG.API_SERVER_WEB}/token/createToken`, {
    method: "POST",
    body: {
      ...params,
    }
  });
}
export async function verifyToken(token) {
  return request(`${CONFIG.API_SERVER_WEB}/token/verifyToken?token=${token}`);
}
export async function queryBankAccount(params) {
  return request(`${CONFIG.API_SERVER_WEB}/bankAccount?${stringify(params)}`);
}
export async function queryUnitBranch(params) {
  return request(`${CONFIG.API_SERVER_WEB}/unitBranch?${stringify(params)}`);
}
export async function checkFee(params) {
  return request(`${CONFIG.API_SERVER_WEB}/baokim/checkFee`, {
    method: "POST",
    body: { ...params }
  });
}

export async function queryPaymentMethods(query) {
  return request(`${CONFIG.API_SERVER_WEB}/paymentMethods?${stringify(query)}`)
}

export async function queryVouchersMoney(query) {
  return request(`${CONFIG.API_SERVER_WEB}/vouchers/getMoney`, {
    method: "POST",
    body: { ...query }
  })
}

export async function queryAirports(params) {
  return request(`${CONFIG.API_SERVER_WEB}/airports?${stringify(params)}`);
}

export async function querySearchAirports(params) {
  return request(`${CONFIG.API_SERVER_WEB}/airports/getAirports?${stringify(params)}`);
}

export async function queryAirLaneData(params) {
  return request(`${CONFIG.API_SERVER_WEB}/airlines?${stringify(params)}`);
}