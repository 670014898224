import websModels from './websModels';
import cartModels from './cartModels';
import shoppingCart from './shoppingCart';
import manager from './managerModels';

const model = [
  websModels,
  shoppingCart,
  cartModels,
  manager,
];

export default model;